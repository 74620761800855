import React from 'react';
import propTypes from 'prop-types';

import avatar_webp from './../img/avatar_gray.png';
import avatar_png from './../img/avatar_gray.webp';

import Social from './Social';

const About = ({avatar, name, profession, bio, address, social}) => {
  const avatarImg = (
    <picture>
      <source type="image/webp" srcSet={avatar_webp} />
      <img src={avatar_png} alt={name} />
    </picture>
  )

  return(
    <div className='bio'>
      <div className='avatar'>
        {avatarImg}
      </div>
      <div className='title'>
        <h1>{name}</h1>
        <h2>{profession}</h2>
      </div>
      <div className='desc'>
        <p>{bio}</p>
      </div>
      <div className='address'>
        <p>{address}</p>
      </div>
      <Social social={social} />
    </div>
  );
};

About.propTypes = {
  avatar: propTypes.object,
  name: propTypes.string,
  profession: propTypes.string,
  bio: propTypes.string,
  address: propTypes.string,
  social: propTypes.array
};

export default About;
