import React from "react"

const Description = props => {
  const myDescription = (
    <div className="item">
      {props.description.map(description => (
        <p key={description.id}>{description.string}</p>
      ))}
    </div>
  )
  return (
    <div className="title">
      <i className="fa fa-id-card-o" />
      <h2>Highlights of Qualifications</h2>
      {myDescription}
    </div>
  )
}

export default Description
