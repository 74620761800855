import React from "react"

class Responsibilits extends React.Component {
  render() {
    return (
      <ul>
        {this.props.responsibilities.map(resp => (
          <li key={resp.id}>{resp.resp}</li>
        )) }
      </ul>
    )
  }
}

const Experience = props => {
  const myExperience = (
    <div>
      {props.experience.map(exp => (
        <div className="item" key={exp.jobTitle}>
          <h3>
            {exp.jobTitle} @ {exp.company}{" "}
            <span>
              {exp.startDate} - {exp.endDate}
            </span>
          </h3>
          <Responsibilits responsibilities={exp.jobDescription} />
        </div>
      ))}
    </div>
  )

  return (
    <div className="title">
      <i className="fa fa-briefcase" />
      <h2>Professional Experience</h2>
      {myExperience}
    </div>
  )
}

export default Experience
