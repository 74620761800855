import React from "react"

const Searching = props => {
  return (
    <div className="title">
      <i className="fa fa-search" />
      <h2>I'm searching for</h2>
      <div className="item">
        <p>{props.searching[0]}</p>
      </div>
    </div>
  )
}

export default Searching
