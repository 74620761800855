import * as React from 'react'
import { view } from 'react-easy-state'

// import './styles/main.scss'

import About from "./components/About"
import Description from "./components/Description"
import Searching from "./components/Searching"
import Experience from "./components/Experience"
import Education from "./components/Education"
import Certificate from "./components/Certificate"
import Skills from "./components/Skills"


import person from './lib/person.json';

function Content(props) {
  const sidebar = (
    <div className="sidebar">
      <About
        avatar={person.avatar}
        name={person.name}
        profession={person.profession}
        bio={person.bio}
        address={person.address}
        social={person.social}
      />
    </div>
  );
  return (
    <div className="wrapper">

      {sidebar}

      <div className="contentWrapper">
        <div className="content">
          <Description description={person.description} />
          <Searching searching={person.searching} />
          <Experience experience={person.experience} />
          <Skills skills={person.skills} />
          <Education education={person.education} />
          <Certificate certificate={person.certificate} />
        </div>
      </div>
    </div>
  );
}


export default view(() => {
  return (
    <header>
      <Content person={person} />
    </header>
  )
})